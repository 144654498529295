import React, { useState, useEffect, useCallback, useRef } from 'react';
import { IonContent, IonList, IonInfiniteScroll, IonInfiniteScrollContent, IonRefresher, IonRefresherContent, IonSpinner } from '@ionic/react';
import { RefresherEventDetail } from '@ionic/core';
import AuctionItem from './AuctionItem';
import { supabase } from '../../supabaseClient';

interface AuctionListProps {
  searchTerm: string;
  selectedRarity: string | null;
  selectedPriceRange: string | null;
  selectedBusinessType: string | null;
}

const AuctionList: React.FC<AuctionListProps> = ({
  searchTerm,
  selectedRarity,
  selectedPriceRange,
  selectedBusinessType
}) => {
  const [auctions, setAuctions] = useState<any[]>([]);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [hasMore, setHasMore] = useState(true);
  const PAGE_SIZE = 20;

  const prevSearchParamsRef = useRef({ searchTerm, selectedRarity, selectedPriceRange, selectedBusinessType });
  const initialLoadRef = useRef(true);

  const fetchAuctions = useCallback(async (reset = false) => {
    if (isLoading) return;
    
    setIsLoading(true);
    const currentPage = reset ? 1 : page;

    let minPrice: number | null = null;
    let maxPrice: number | null = null;
    if (selectedPriceRange) {
      [minPrice, maxPrice] = selectedPriceRange.split('-').map(Number);
    }

    const { data, error } = await supabase
      .rpc('search_auctions', {
        p_search_term: searchTerm || null,
        p_min_price: minPrice,
        p_max_price: maxPrice,
        p_business_type: selectedBusinessType,
        p_rarity: selectedRarity,
        p_page: currentPage,
        p_page_size: PAGE_SIZE
      });

    if (error) {
      //console.error('Error fetching auctions:', error);
    } else {
      setAuctions(prev => reset ? data : [...prev, ...data]);
      setHasMore(data.length === PAGE_SIZE);
      setPage(prev => reset ? 2 : prev + 1);
    }
    setIsLoading(false);
    setIsInitialLoad(false);
    initialLoadRef.current = false;
  }, [searchTerm, selectedRarity, selectedPriceRange, selectedBusinessType, page]);

  useEffect(() => {
    const currentSearchParams = { searchTerm, selectedRarity, selectedPriceRange, selectedBusinessType };
    if (JSON.stringify(currentSearchParams) !== JSON.stringify(prevSearchParamsRef.current) || initialLoadRef.current) {
      setIsInitialLoad(true);
      setPage(1);
      fetchAuctions(true);
      prevSearchParamsRef.current = currentSearchParams;
    }
  }, [searchTerm, selectedRarity, selectedPriceRange, selectedBusinessType, fetchAuctions]);

  const handleRefresh = async (event: CustomEvent<RefresherEventDetail>) => {
    setPage(1);
    await fetchAuctions(true);
    event.detail.complete();
  };

  const handleInfiniteScroll = async (e: CustomEvent<void>) => {
    if (!isLoading && hasMore) {
      await fetchAuctions();
    }
    (e.target as HTMLIonInfiniteScrollElement).complete();
  };

  if (isInitialLoad) {
    return (
      <IonContent className="ion-padding bg-white/80 backdrop-blur-xl">
        <div className="flex flex-col items-center justify-center h-full">
          <IonSpinner name='lines' />
          <p className="mt-2">Loading...</p>
        </div>
      </IonContent>
    );
  }

  return (
    <IonContent className="ion-padding bg-white/80 backdrop-blur-xl">
      <IonRefresher slot="fixed" mode='ios' onIonRefresh={handleRefresh}>
        <IonRefresherContent></IonRefresherContent>
      </IonRefresher>
      <IonList>
        {auctions.map((auction) => (
          <AuctionItem
            key={auction.auction_id}
            auctionId={auction.auction_id}
            businessName={auction.business_name}
            rarity={auction.rarity}
            currentPrice={auction.current_price}
            bids={auction.num_bids || 0}
            timeLeft={auction.end_time}
            onBid={() => fetchAuctions(true)}
          />
        ))}
      </IonList>
      <IonInfiniteScroll
        onIonInfinite={handleInfiniteScroll}
        threshold="100px"
        disabled={!hasMore || isLoading}
      >
        <IonInfiniteScrollContent
          loadingSpinner="crescent"
          loadingText="Loading more auctions..."
        />
      </IonInfiniteScroll>
    </IonContent>
  );
};

export default AuctionList;