// AuctionItem.tsx
import React, { useState, useEffect } from 'react';
import {
  IonItem,
  IonLabel,
  IonBadge,
  IonButton,
  IonIcon,
  IonText,
} from '@ionic/react';
import { peopleOutline, timeOutline } from 'ionicons/icons';
import BiddingModal from './BiddingModal';
import { formatMoney } from '../../utils/formatMoney';

interface AuctionItemProps {
  auctionId: string;
  businessName: string;
  rarity: string;
  currentPrice: number;
  bids: number;
  timeLeft: string;
  onBid: () => void;
}

const AuctionItem: React.FC<AuctionItemProps> = ({
  auctionId,
  businessName,
  rarity,
  currentPrice,
  bids,
  timeLeft,
  onBid
}) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [remainingTime, setRemainingTime] = useState('');
  const [bidCount, setBidCount] = useState(bids);

  useEffect(() => {
    const updateRemainingTime = () => {
      const now = new Date();
      const end = new Date(timeLeft);
      const diff = end.getTime() - now.getTime();

      if (diff <= 0) {
        setRemainingTime('Ended');
        return;
      }

      const seconds = Math.floor(diff / 1000);
      const minutes = Math.floor(seconds / 60);
      const hours = Math.floor(minutes / 60);
      const days = Math.floor(hours / 24);
      const weeks = Math.floor(days / 7);

      let timeString = '';
      if (weeks > 0) {
        timeString += `${weeks}w `;
      }
      if (days > 0 || weeks > 0) {
        timeString += `${days % 7}d `;
      }
      if (hours > 0 || days > 0 || weeks > 0) {
        timeString += `${hours % 24}h `;
      }
      timeString += `${minutes % 60}m ${seconds % 60}s left`;

      setRemainingTime(timeString);
    };

    updateRemainingTime();
    const timer = setInterval(updateRemainingTime, 1000);

    return () => clearInterval(timer);
  }, [timeLeft]);

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleBidPlaced = (newBidCount: number) => {
    setBidCount(newBidCount);
    onBid(); // This will trigger a refresh of the auction list
  };

  const rarityColor = (rarity: string) => {
    switch (rarity) {
      case 'Common': return 'medium';
      case 'Rare': return 'success';
      case 'Epic': return 'tertiary';
      case 'Legendary': return 'danger';
      default: return 'primary';
    }
  };

  return (
    <>
      <IonItem button onClick={handleOpenModal} detail={false} className="bg-transparent ion-align-items-start !rounded-none border-b -mr-6">
        <IonLabel>
          <h2 className="!text-md !font-bold">{businessName || 'Unknown Business'}</h2>
          <p className="flex flex-col sm:flex-row sm:items-center text-xl text-black !font-normal">
            <span className="whitespace-nowrap">Current bid: {`${formatMoney(currentPrice)}`}</span>
            <span className="hidden sm:inline ml-2">•</span>
            <span className="flex items-center sm:ml-2 mt-1 sm:mt-0">
              <IonIcon icon={timeOutline} className="mr-1 text-xl" />
              <span className="whitespace-nowrap">{remainingTime}</span>
            </span>
          </p>
          <IonBadge mode='ios' className="mt-1 !text-xs" color={rarityColor(rarity || 'Unknown')}>{rarity || 'Unknown'}</IonBadge>
        </IonLabel>
        <IonLabel className="ion-text-end mt-6">
          <IonButton color="primary" size="small" shape="round" onClick={handleOpenModal} className="self-end !rounded-full">
            Place Bid
          </IonButton>
          <IonText color="medium" className="block text-xs mt-1 mr-8">
            <IonIcon icon={peopleOutline} className="mr-1" />
            {bidCount} bids
          </IonText>
        </IonLabel>
      </IonItem>
      <BiddingModal
        isOpen={isModalOpen}
        onClose={() => setModalOpen(false)}
        currentPrice={currentPrice}
        biddersHistory={[]} // You'll need to fetch this data from Supabase
        
        auctionId={auctionId}
        onBidPlaced={handleBidPlaced}
      />
    </>
  );
};

export default AuctionItem;