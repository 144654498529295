import React, { useState, useEffect, useRef, forwardRef } from 'react';
import { IonContent, IonPage, IonToast, IonAlert, IonModal } from '@ionic/react';
import { supabase } from '../supabaseClient';
import { useHistory } from 'react-router-dom';
import Globe, { GlobeMethods } from 'react-globe.gl';
import * as THREE from 'three';
import './RestaurantDetailsModal.css';

// Remove this unused interface
// interface GlobeProps {
//   globeImageUrl: string;
//   bumpImageUrl: string;
//   backgroundImageUrl: string;
// }

const Auth: React.FC = () => {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [showOtpAlert, setShowOtpAlert] = useState(false);
  const [modalOpen] = useState(true);
  const history = useHistory();
  const modal = useRef<HTMLIonModalElement>(null);

  const handleSendOtp = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    try {
      const { error } = await supabase.auth.signInWithOtp({
        email: email,
      });
      if (error) throw error;
      setToastMessage('Check your email for the OTP!');
      setShowToast(true);
      setShowOtpAlert(true);
    } catch (error: any) {
      setToastMessage(error.message);
      setShowToast(true);
    } finally {
      setLoading(false);
    }
  };

  const createPlayerProfile = async (userId: string) => {
    try {
      const { data, error } = await supabase
        .from('players')
        .insert([
          { id: userId, username: email.split('@')[0], email: email, balance: 10000 } // Set an initial balance
        ]);

      if (error) throw error;
    } catch (error: any) {
      setToastMessage('Failed to create player profile. Please try again.');
      setShowToast(true);
    }
  };

  const handleVerifyOtp = async (otp: string) => {
    try {
      const { data, error } = await supabase.auth.verifyOtp({
        email,
        token: otp,
        type: 'email'
      });
      if (error) throw error;

      if (data.user) {
        // Check if player profile already exists
        const { data: existingProfile } = await supabase
          .from('players')
          .select()
          .eq('id', data.user.id)
          .single();

        if (!existingProfile) {
          await createPlayerProfile(data.user.id);
        }
      }

      setToastMessage('Successfully authenticated!');
      setShowToast(true);
      history.push('/map');
    } catch (error: any) {
      setToastMessage(error.message);
      setShowToast(true);
    }
  };

  return (
    <IonPage>
      <div
        style={{
          position: 'absolute',
          width: '100%',
          height: '100%',
          backgroundImage: 'url(/assets/pikaso_texttoimage_Painting-of-a-chef-looking-at-the-landscape-of-a-m.jpeg)',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundAttachment: 'fixed', /* Ensures the background remains fixed */
        }}
      />
      <IonContent fullscreen className="">
        <IonModal
          canDismiss={false}
          ref={modal}
          initialBreakpoint={0.25}
          breakpoints={[0.25, 0.5, 0.80]}
          isOpen={modalOpen}
          className="!rounded-sm restaurant-modal"
        >
          <IonContent className="ion-padding !glassmorphism-content">
            <div className="flex  justify-center min-h-full">
              <div className="text-left rounded-lg w-full max-w-md">
                <h3 className="text-3xl font-bold text-center text-gray-800">Enter your email</h3>
                <form onSubmit={handleSendOtp}>
                  <div className="mt-4">
                    <div>
                      <input
                        type="email"
                        placeholder="your@email.com"
                        id="email"
                        className="w-full px-4 py-5 border rounded-3xl focus:outline-none focus:ring-1 focus:ring-blue-600"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        // on click set breakpoint to 0.75
                        onFocus={() => modal.current?.setCurrentBreakpoint(0.80)}
                        required
                      />
                    </div>
                    <div className="flex items-baseline justify-between w-full">
                      <button
                        type="submit"
                        className="px-6 py-4 mt-4 text-white bg-blue-600 w-full rounded-3xl hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-opacity-50"
                        disabled={loading}
                      >
                        {loading ? 'Sending...' : 'Send OTP'}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </IonContent>
        </IonModal>
        <IonToast
          isOpen={showToast}
          onDidDismiss={() => setShowToast(false)}
          message={toastMessage}
          duration={5000}
        />
        <IonAlert
          isOpen={showOtpAlert}
          onDidDismiss={() => setShowOtpAlert(false)}
          header={'Enter OTP'}
          message={'Please enter the One-Time Password sent to your email.'}
          className="auth-alert"
          mode='ios'
          inputs={[
            {
              name: 'otp',
              id: 'otp',
              type: 'tel',
              
              placeholder: 'Enter your OTP'
            }
          ]}
          buttons={[
            {
              text: 'Verify',
              handler: (data) => {
                handleVerifyOtp(data.otp);
              }
            }
          ]}
        />
      </IonContent>
    </IonPage>
  );
};

export default Auth;