import React, { useState, useEffect, useRef } from "react";
import { IonModal, IonContent, IonButton, IonRange, IonLabel, IonItem, IonAlert, IonCard, IonCardContent, IonSpinner } from "@ionic/react";
import { supabase } from "../supabaseClient";
import './RestaurantDetailsModal.css';

interface RestaurantDetailsModalProps {
  isOpen: boolean;
  onClose: () => void;
  restaurantId: string | null;
  setReloadData: (reloadData: boolean) => void;
  reloadData: boolean;
}

interface Owner {
  id: string;
  username: string;
  percentage: number;
}

interface Business {
  id: string;
  name: string;
  category: string;
  business_type: string;
  valuation: number;
}

interface User {
  id: string;
  // Add other user properties as needed
}

interface AuctionDetails {
  startingPrice: number;
  durationDays: number;
}

interface ActiveAuction {
  id: string;
  currentPrice: number;
  endTime: string;
}

const RestaurantDetailsModal: React.FC<RestaurantDetailsModalProps> = ({
  isOpen,
  onClose,
  restaurantId,
  setReloadData,
  reloadData
}) => {
  const modal = useRef<HTMLIonModalElement>(null);
  const [restaurant, setRestaurant] = useState<Business | null>(null);
  const [owners, setOwners] = useState<Owner[]>([]);
  const [sharesToBuy, setSharesToBuy] = useState<number>(0);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const [userBalance, setUserBalance] = useState<number>(0);
  const [showAuctionForm, setShowAuctionForm] = useState<boolean>(false);
  const [localStartingPrice, setLocalStartingPrice] = useState<string>('');
  const [localDurationDays, setLocalDurationDays] = useState<string>('');
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<string>("");
  const [activeAuction, setActiveAuction] = useState<ActiveAuction | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (isOpen && restaurantId) {
      setIsLoading(true);
      Promise.all([
        fetchRestaurantDetails(),
        fetchOwners(),
        fetchCurrentUser()
      ]).then(() => {
        setIsLoading(false);
      });
    }
  }, [isOpen, restaurantId]);

  useEffect(() => {
    if (restaurant) {
      setLocalStartingPrice(restaurant.valuation.toString());
      setLocalDurationDays('3');
      fetchActiveAuction();
    }
  }, [restaurant]);

  const logError = (message: string, error: any) => {
    //console.error(message, error);
  };

  const fetchCurrentUser = async () => {
    const { data: { user }, error } = await supabase.auth.getUser();
    if (error) {
      logError("Error fetching current user:", error);
      setError("Unable to fetch user information");
    } else {
      setCurrentUser(user as User);
      fetchUserBalance(user?.id ?? '');
    }
  };

  const fetchUserBalance = async (userId: string) => {
    const { data, error } = await supabase
      .from('user_financials')
      .select('cash_available')
      .eq('user_id', userId)
      .single();

    if (error) {
      logError("Error fetching user balance:", error);
    } else {
      setUserBalance(data.cash_available);
    }
  };

  const fetchRestaurantDetails = async () => {
    const { data, error } = await supabase
      .from("businesses")
      .select("*")
      .eq("id", restaurantId)
      .single();

    if (error) {
      logError("Error fetching restaurant details:", error);
    } else {
      setRestaurant(data);
    }
  };

  const fetchOwners = async () => {
    const { data, error } = await supabase.rpc("get_restaurant_owners", {
      restaurant_id: restaurantId,
    });

    if (error) {
      logError("Error fetching owners:", error);
    } else {
      setOwners(data);
    }
  };

  const handlePurchase = async () => {
    setError(null);
    setSuccess(null);

    if (!currentUser) {
      setError("User not authenticated");
      return;
    }

    const { data, error } = await supabase.rpc("purchase_restaurant_shares", {
      restaurant_id: restaurantId,
      shares_to_buy: sharesToBuy,
      input_user_id: currentUser.id
    });

    if (error) {
      setError(error.message);
    } else if (data && data.success) {
      setSuccess(`Successfully purchased ${sharesToBuy} shares!`);
      fetchOwners();
      fetchUserBalance(currentUser.id);
      setReloadData(true);
      setSharesToBuy(0);
    } else if (data && !data.success) {
      setError(data.message || "Failed to purchase shares");
    }
  };

  const calculateBuyingPrice = () => {
    if (!restaurant) return 0;
    return (sharesToBuy / 100) * restaurant.valuation;
  };

  const maxAffordableShares = () => {
    if (!restaurant || restaurant.valuation === 0) return 0;
    const currentUserOwnership = owners.find(owner => owner.id === currentUser?.id);
    const currentOwnership = currentUserOwnership ? currentUserOwnership.percentage : 0;
    const maxPurchasablePercentage = 100 - currentOwnership;
    const affordablePercentage = (userBalance / restaurant.valuation) * 100;
    return Math.min(maxPurchasablePercentage, affordablePercentage);
  };

  const isFullOwner = () => {
    const currentUserOwnership = owners.find(owner => owner.id === currentUser?.id);
    return currentUserOwnership && currentUserOwnership.percentage === 100;
  };

  const fetchActiveAuction = async () => {
    if (!restaurant || !currentUser) return;

    const { data, error } = await supabase
      .from('auctions')
      .select('id, current_price, end_time')
      .eq('business_id', restaurant.id)
      .eq('status', 'active')
      .single();

    if (error) {
      logError("Error fetching active auction:", error);
    } else if (data) {
      setActiveAuction({
        id: data.id,
        currentPrice: data.current_price,
        endTime: data.end_time,
      });
    }
  };

  const handleStartAuction = async () => {
    if (!restaurant || !currentUser) return;

    const startingPrice = parseFloat(localStartingPrice) || 0;
    const durationDays = parseInt(localDurationDays) || 1;

    try {
      const { data, error } = await supabase.rpc('create_auction', {
        p_business_id: restaurant.id,
        p_seller_id: currentUser.id,
        p_starting_price: startingPrice,
        p_duration_days: durationDays
      });

      if (error) throw error;

      setAlertMessage("Auction created successfully!");
      setShowAlert(true);
      setShowAuctionForm(false);
      fetchActiveAuction();
    } catch (error: any) {
      setAlertMessage(`Failed to create auction: ${error.message}`);
      setShowAlert(true);
    }
  };

  return (
    <IonModal
      ref={modal}
      isOpen={isOpen}
      onDidDismiss={onClose}
      breakpoints={[0, 0.25, 0.5, 0.75, 0.9]}
      initialBreakpoint={0.65}
      className="restaurant-modal"
    >
      <IonContent className="ion-padding !glassmorphism-content">
        {isLoading ? (
          <div className="flex items-center justify-center h-full -mt-44">
            <IonSpinner name="lines" />
          </div>
        ) : !restaurant || !currentUser ? (
          <div className="flex items-center justify-center h-full">
            <p>Error loading restaurant data</p>
          </div>
        ) : (
          <div className="space-y-4 glassmorphism-content mt-2">
            <section className="flex flex-col gap-4 animate-in fade-in duration-300">
              <div className="bg-white rounded-3xl p-4 gap-3 flex flex-col w-full h-fit">
                <div className="flex flex-col gap-1">
                  <div className="flex flex-row w-full justify-between items-center">
                    <div className="flex flex-row items-center gap-2">
                      <button onClick={onClose} data-shown="true" className="justify-center items-center place-self-start w-6 h-10 cursor-pointer hidden data-[shown=true]:flex">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="min-w-8 h-8 stroke-[1.5]">
                          <polyline points="15 18 9 12 15 6"></polyline>
                        </svg>
                      </button>
                      <h1 className="text-3xl font-display font-semibold lowercase">{restaurant.name}</h1>
                    </div>
                  </div>
                  <p className="text-gray-600">{restaurant.category}</p>
                  <p className="text-gray-600">{restaurant.business_type}</p>
                  <p className="text-lg font-semibold">Business Value: ${restaurant.valuation && restaurant.valuation.toLocaleString()}</p>
                  <p className="text-lg font-semibold text-green-500">Your Balance: ${userBalance.toLocaleString()}</p>
                </div>
              </div>
            </section>

            {/* Current Owners section */}
            <div className="bg-white rounded-3xl p-4">
              <h3 className="text-xl font-semibold mb-2">Current Owners</h3>
              {owners.map((owner) => (
                <div key={owner.id} className="mb-2 flex flex-row justify-between">
                  <h4>{owner.username}</h4>
                  <p>{owner.percentage.toFixed(2)}% ownership</p>
                </div>
              ))}
            </div>

            {/* Auction section */}
            {isFullOwner() && (
              <div className="bg-white rounded-3xl p-4">
                <h3 className="text-xl font-semibold mb-2">Auction</h3>
                {activeAuction ? (
                  <IonCard>
                    <IonCardContent>
                      <p>Current Price: ${activeAuction.currentPrice.toLocaleString()}</p>
                      <p>End Time: {new Date(activeAuction.endTime).toLocaleString()}</p>
                    </IonCardContent>
                  </IonCard>
                ) : !showAuctionForm ? (
                  <button
                    className="w-full py-2 px-4 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition duration-200"
                    onClick={() => setShowAuctionForm(true)}
                  >
                    Start Auction
                  </button>
                ) : (
                  <div className="space-y-4">
                    <div>
                      <label htmlFor="startingPrice" className="block text-sm font-medium text-gray-700">
                        Starting Price ($)
                      </label>
                      <input
                        type="tel"
                        id="startingPrice"
                        value={localStartingPrice}
                        onChange={(e) => setLocalStartingPrice(e.target.value)}
                        className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                      />
                    </div>
                    <div>
                      <label htmlFor="durationDays" className="block text-sm font-medium text-gray-700">
                        Duration (days)
                      </label>
                      <input
                        type="tel"
                        id="durationDays"
                        value={localDurationDays}
                        onChange={(e) => setLocalDurationDays(e.target.value)}
                        className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                      />
                    </div>
                    <button
                      className="w-full py-2 px-4 bg-green-500 text-white rounded-md hover:bg-green-600 transition duration-200"
                      onClick={handleStartAuction}
                    >
                      Confirm Auction
                    </button>
                    <button
                      className="w-full py-2 px-4 bg-gray-300 text-gray-700 rounded-md hover:bg-gray-400 transition duration-200"
                      onClick={() => setShowAuctionForm(false)}
                    >
                      Cancel
                    </button>
                  </div>
                )}
              </div>
            )}

            {/* Purchase Shares section */}
            <div className="bg-white rounded-3xl p-4">
              <h3 className="text-xl font-semibold mb-2">Purchase Shares</h3>
              <IonRange
                min={0}
                max={maxAffordableShares()}
                step={0.01}
                mode="ios"
                value={sharesToBuy}
                onIonChange={(e) => setSharesToBuy(e.detail.value as number)}
              >
                <IonLabel slot="start">0%</IonLabel>
                <IonLabel slot="end">{maxAffordableShares().toFixed(2)}%</IonLabel>
              </IonRange>
              <p className="mt-2">Shares to buy: {sharesToBuy.toFixed(2)}%</p>
              <p className="mt-2">Buying price: ${calculateBuyingPrice().toLocaleString()}</p>
              <IonButton
                expand="block"
                onClick={handlePurchase}
                disabled={sharesToBuy <= 0}
                color="primary"
              >
                Purchase Shares
              </IonButton>
            </div>

            {error && <p className="text-red-500">{error}</p>}
            {success && <p className="text-green-500">{success}</p>}

            <IonAlert
              isOpen={showAlert}
              onDidDismiss={() => setShowAlert(false)}
              header="Auction Status"
              message={alertMessage}
              buttons={['OK']}
            />
          </div>
        )}
      </IonContent>
    </IonModal>
  );
};

export default RestaurantDetailsModal;