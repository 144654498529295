import React, { useEffect, useState } from 'react';
import { Plugins } from '@capacitor/core';

const { CapacitorLiveUpdater } = Plugins;

const SplashScreen: React.FC<{ onUpdateComplete: () => void }> = ({ onUpdateComplete }) => {
  const [updateStatus, setUpdateStatus] = useState('Checking for updates...');

  useEffect(() => {
    const checkAndApplyUpdate = async () => {
      try {
        const result = await CapacitorLiveUpdater.checkAndApplyUpdate({
          versionUrl: process.env.REACT_APP_VERSION_URL || '',
          updateUrl: process.env.REACT_APP_UPDATE_URL || ''
        });
        //console.log('Update check result:', result);
        setUpdateStatus('Update complete');
        onUpdateComplete();
      } catch (error) {
        //console.error('Error during update check:', error);
        setUpdateStatus('Update failed');
        onUpdateComplete();
      }
    };

    checkAndApplyUpdate();
  }, [onUpdateComplete]);

  return (
    <div>
      <h1>YesChef</h1>
      <p>{updateStatus}</p>
    </div>
  );
};

export default SplashScreen;