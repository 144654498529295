import React, { useRef, useEffect, useState, useCallback } from 'react';
import Map, { MapRef, Marker, Projection } from 'react-map-gl';
import SuperCluster, { PointFeature } from 'supercluster';
import 'mapbox-gl/dist/mapbox-gl.css';
import { debounce } from 'lodash';
import { supabase } from '../../supabaseClient';

interface Business {
    id: number;
    name: string;
    longitude: number;
    latitude: number;
}

interface MapBoxProps {
    onMarkerClick: (business: Business) => void;
    initialViewState: {
        longitude: number;
        latitude: number;
        zoom: number;
    };
    mapRef: React.RefObject<MapRef>;
    setIsMapLoaded: React.Dispatch<React.SetStateAction<boolean>>;
}

const RESTAURANT_ICON = (
    <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23" fill="none"
    >
        <circle cx="11.7391" cy="11.7391" r="9.5" fill="#0C79FE" stroke="white" strokeWidth="3" />
    </svg>
);

const LOAD_BUFFER = 0.1; // Load businesses slightly outside the visible area
const MIN_ZOOM_TO_LOAD = 10;

const MapBox: React.FC<MapBoxProps> = ({ onMarkerClick, initialViewState, mapRef, setIsMapLoaded }) => {
    const [businesses, setBusinesses] = useState<Business[]>([]);
    const [clusters, setClusters] = useState<any[]>([]);
    const supercluster = useRef(new SuperCluster({ radius: 40, maxZoom: 16 }));

    const loadBusinesses = useCallback(async (bounds: [number, number, number, number]) => {
        const [minLng, minLat, maxLng, maxLat] = bounds;
        try {
            const { data, error } = await supabase.rpc('businesses_in_view', {
                min_lat: minLat,
                min_long: minLng,
                max_lat: maxLat,
                max_long: maxLng,
                zoom_level: Math.floor(mapRef.current?.getZoom() || 0)
            });

            if (error) throw error;

            setBusinesses(data as Business[]);
        } catch (error) {
            // no console.log

        }
    }, []);

    const debouncedLoadBusinesses = useCallback(
        debounce((bounds: [number, number, number, number]) => loadBusinesses(bounds), 300),
        [loadBusinesses]
    );

    // Callback when the map has loaded
    const handleMapLoad = () => {
        setIsMapLoaded(true);
    };

    const updateClusters = useCallback(() => {
        const map = mapRef.current?.getMap();
        if (!map) return;
    
        const bounds = map.getBounds().toArray().flat() as [number, number, number, number];
        const zoom = map.getZoom();
    
        // Dynamically adjust the radius based on zoom level
        const dynamicRadius = zoom > 14 ? 320 : zoom > 12 ? 800 : 880;
    
        supercluster.current = new SuperCluster({
            radius: dynamicRadius, // Use smaller radius for higher zoom levels
            maxZoom: 16,
        });
    
        supercluster.current.load(
            businesses.map(business => ({
                type: 'Feature',
                properties: { cluster: false, businessId: business.id, ...business },
                geometry: { type: 'Point', coordinates: [business.longitude, business.latitude] }
            }))
        );
    
        setClusters(supercluster.current.getClusters(bounds, Math.floor(zoom)));
    }, [businesses]);

    const handleMapMove = useCallback(() => {
        const map = mapRef.current?.getMap();
        if (!map) return;

        const bounds = map.getBounds().toArray().flat() as [number, number, number, number];
        debouncedLoadBusinesses(bounds);
        updateClusters();
    }, [debouncedLoadBusinesses, updateClusters]);

    useEffect(() => {
        updateClusters();
    }, [businesses, updateClusters]);

    return (
        <Map
            ref={mapRef}
            initialViewState={{
                longitude: -75.6959652,
                latitude: 45.4179255,
                zoom: 15.93,
                pitch: 51,
                bearing: -76
            }}
            projection={'globe' as unknown as Projection}
            style={{ width: '100%', height: '100%' }}
            mapStyle={process.env.REACT_APP_MAPBOX_MAP_STYLE}
            mapboxAccessToken={process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}
            onMove={handleMapMove}
            attributionControl={false}
            onLoad={handleMapLoad}
            terrain={{ source: 'mapbox-dem', exaggeration: 1.5 }}
        >
            {clusters.map(cluster => {
                const [longitude, latitude] = cluster.geometry.coordinates;
                const { cluster: isCluster, point_count: pointCount } = cluster.properties;

                if (isCluster) {
                    const minSize = 30; // Minimum cluster size
                    const maxSize = 60; // Maximum cluster size
                    const size = Math.min(maxSize, minSize + (pointCount / clusters.length) * 100);

                    return (
                        <Marker key={`cluster-${cluster.id}`} longitude={longitude} latitude={latitude}>
                            <div
                                style={{
                                    width: `${size}px`,
                                    height: `${size}px`,
                                    borderRadius: '50%',
                                    border: '2px solid white',
                                    backgroundColor: '#0C79FE',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    color: 'white',
                                    fontWeight: 'bold',
                                    fontSize: '16px',
                                }}
                                onClick={() => {
                                    const expansionZoom = Math.min(
                                        supercluster.current.getClusterExpansionZoom(cluster.id),
                                        20
                                    );
                                    mapRef.current?.flyTo({
                                        center: [longitude, latitude],
                                        zoom: expansionZoom,
                                        duration: 500,
                                    });
                                }}
                            >
                                {pointCount}
                            </div>
                        </Marker>
                    );
                }

                return (
                    <Marker
                        key={`business-${cluster.properties.businessId}`}
                        longitude={longitude}
                        latitude={latitude}
                        onClick={() => onMarkerClick(cluster.properties)}
                    >
                        <div className="flex flex-col items-center">
                            <div className="bg-white text-[#1e1e1e] border border-[#AEAAA3] px-2 py-1 text-sm font-semibold rounded">
                                {cluster.properties.name}
                            </div>
                            <div className="marker-3d">
                                {RESTAURANT_ICON}
                            </div>
                        </div>
                    </Marker>
                );
            })}
        </Map>
    );
};

export default MapBox;