import React, { useRef, useState, useEffect } from 'react';
import {
  IonButton,
  IonModal,
  IonHeader,
  IonContent,
  IonToolbar,
  IonTitle,
  IonItem,
  IonLabel,
  IonList,
  IonText,
  IonSegment,
  IonSegmentButton,
  IonSpinner,
  IonToast,
} from '@ionic/react';
import { supabase } from '../../supabaseClient';
import { formatMoney } from '../../utils/formatMoney';
import './BiddingModal.css'

interface BiddingModalProps {
  isOpen: boolean;
  onClose: () => void;
  currentPrice: number;
  biddersHistory: { name: string; amount: number }[];
  auctionId: string;
  onBidPlaced: (newBidCount: number) => void;
}

interface BidHistory {
  bidder_id: string;
  amount: number;
  bid_time: string;
}

const BiddingModal: React.FC<BiddingModalProps> = ({
  isOpen,
  onClose,
  currentPrice,
  biddersHistory,
  auctionId,
  onBidPlaced,
}) => {
  const [bidAmount, setBidAmount] = useState<number>(currentPrice + 1);
  const [selectedSegment, setSelectedSegment] = useState<'bid' | 'history'>('bid');
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [userId, setUserId] = useState<string | null>(null);
  const [bidHistory, setBidHistory] = useState<BidHistory[]>([]);
  const modal = useRef<HTMLIonModalElement>(null);

  useEffect(() => {
    setBidAmount(currentPrice + 1);
  }, [currentPrice]);

  useEffect(() => {
    const fetchUserId = async () => {
      const { data: { user } } = await supabase.auth.getUser();
      if (user) {
        setUserId(user.id);
      } else {
        setErrorMessage('You must be logged in to place a bid.');
      }
    };
    fetchUserId();
  }, []);

  useEffect(() => {
    const fetchBidHistory = async () => {
      const { data, error } = await supabase.rpc('get_bid_history', { p_auction_id: auctionId });
      if (error) {
        //console.error('Error fetching bid history:', error);
      } else {
        setBidHistory(data);
      }
    };
    if (selectedSegment === 'history') {
      fetchBidHistory();
    }
  }, [selectedSegment, auctionId]);

  const handleBid = async () => {
    if (!userId) {
      setErrorMessage('You must be logged in to place a bid.');
      return;
    }

    setIsLoading(true);
    setErrorMessage(null);
    setSuccessMessage(null);

    try {
      const { data, error } = await supabase.functions.invoke('push-notifications', {
        body: JSON.stringify({
          auction_id: auctionId,
          bidder_id: userId,
          bid_amount: bidAmount,
        }),
      });

      if (error) {
        // The error object from Supabase might contain the response data
        const errorData = error.context?.response?.data;
        if (errorData && typeof errorData === 'object' && 'error' in errorData) {
          throw new Error(errorData.error);
        } else {
          throw error;
        }
      }

      setSuccessMessage('Bid placed successfully!');
      onBidPlaced(biddersHistory.length + 1);
      setTimeout(() => {
        onClose();
      }, 2000);
    } catch (error: any) {
      setErrorMessage(error.message || 'An error occurred while placing the bid.');
    } finally {
      setIsLoading(false);
    }
  };

  const getBidderLabel = (bidderId: string, index: number) => {
    const bidderIndex = bidHistory.findIndex(bid => bid.bidder_id === bidderId);
    return `Bidder ${bidderIndex + 1}`;
  };

  const highestBid = Math.max(...bidHistory.map(bid => bid.amount), 0);

  return (
    <IonModal
      isOpen={isOpen}
      onDidDismiss={onClose}
      className="ion-modal-auction"
      initialBreakpoint={0.4}
      ref={modal}
      breakpoints={[0, 0.4, 1]}
      mode='ios'
    >
      <IonHeader className="bg-white/80 backdrop-blur-xl !shadow-none" mode='md'>
        <IonToolbar>
          <IonTitle>Place Your Bid</IonTitle>
          <button
            slot="end"
            onClick={onClose}
            className="p-1 pr-[10px] rounded-full w-8 mr-4 bg-gray-200"
            color="light"
          >
            X
          </button>
        </IonToolbar>
      </IonHeader>
      <IonContent className="custom-modal-content bg-white/80 backdrop-blur-xl">
        {/* Segment Control */}
        <IonSegment
          value={selectedSegment}
          mode='ios'
          className='mx-4 mt-2 mr-8 w-[94%]'
          color='light'
          onIonChange={(e) => setSelectedSegment(e.detail.value as 'bid' | 'history')}
        >
          <IonSegmentButton value="bid">
            <IonLabel>Bid</IonLabel>
          </IonSegmentButton>
          <IonSegmentButton value="history">
            <IonLabel>Bid History</IonLabel>
          </IonSegmentButton>
        </IonSegment>

        {/* Conditional rendering based on segment selection */}
        {selectedSegment === 'bid' && (
          <IonList mode="ios" inset={true} lines="inset" className="!mt-4 !rounded-md">
            <IonItem lines="full" className="!bg-transparent !rounded-none !m-0 !-ml-2">
              <IonLabel>
                <h3 className="font-bold !text-md !-mt-2">Current Bid:</h3>
                <IonText className="text-md">{formatMoney(currentPrice)}</IonText>
              </IonLabel>
            </IonItem>
            <input
              type="number"
              placeholder="Enter your bid"
              id="bid"
              className="w-[98%] ml-1 px-4 py-5 border rounded-3xl focus:outline-none focus:ring-1 focus:ring-blue-600"
              value={bidAmount}
              onChange={(e) => setBidAmount(parseFloat(e.target.value))}
              required
              min={currentPrice + 1}
              step="0.01"
              onFocusCapture={() => modal.current?.setCurrentBreakpoint(1)}
            />
            <IonButton
              expand="full"
              color="primary"
              shape="round"
              className="px-0 mt-4"
              onClick={handleBid}
              disabled={isLoading || !userId}
            >
              {isLoading ? <IonSpinner name="crescent" /> : 'Place Bid'}
            </IonButton>
            {errorMessage && (
              <div className="text-red-500 text-sm mt-2 text-center">
                {errorMessage}
              </div>
            )}
          </IonList>
        )}

        {selectedSegment === 'history' && (
          <IonList lines="none" className="mt-4 !bg-transparent">
            <h3 className="text-lg font-semibold !ml-6 !-mb-3">Bid History</h3>
            <div className="!mt-4 ml-2 !-mr-6 overflow-x-auto">
              {bidHistory.length > 0 ? (
                <table className="min-w-full divide-y divide-gray-200 rounded-md">
                  <thead className="bg-gray-50">
                    <tr>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Bidder</th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Bid</th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Time</th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {bidHistory.map((bid, index) => (
                      <tr key={index}>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {getBidderLabel(bid.bidder_id, index)}
                          {bid.amount === highestBid && (
                            <span className="ml-2 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                              High Bid
                            </span>
                          )}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{formatMoney(bid.amount)}</td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{new Date(bid.bid_time).toLocaleString()}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <IonText className="text-center mt-4 ml-5">No bids yet.</IonText>
              )}
            </div>
          </IonList>
        )}
      </IonContent>
      
      <IonToast
        isOpen={!!successMessage}
        message={successMessage || undefined}
        duration={2000}
        color="success"
        onDidDismiss={() => setSuccessMessage(null)}
      />
    </IonModal>
  );
};

export default BiddingModal;
