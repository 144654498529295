import React, { useEffect, useState } from 'react';
import { Redirect, Route, RouteProps, RouteComponentProps } from 'react-router-dom';
import {
  IonApp, IonRouterOutlet, setupIonicReact, IonSpinner,
  IonPage,
  IonContent
} from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { Plugins } from '@capacitor/core';
import { supabase } from './supabaseClient'; // Adjust this import based on your Supabase setup

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import Map from './pages/Map';
import Auth from './components/Auth';
import { AuthProvider, useAuth } from './AuthContext';
import { App as CapacitorApp } from '@capacitor/app'

import { Capacitor } from '@capacitor/core';
import LandingPage from './pages/LandingPage';
import PrivacyPolicy from './pages/PrivacyPolicy';
import UsageTerms from './pages/UsageTerms';
import OneSignal, { OneSignalPlugin } from 'onesignal-cordova-plugin'
import OneSignalInit from "onesignal-cordova-plugin"
import SplashScreen from './components/SplashScreen';
setupIonicReact();

interface ProtectedRouteProps extends Omit<RouteProps, 'component'> {
  component: React.ComponentType<RouteComponentProps<any>>;
}

const UpdateLoadingScreen: React.FC = () => (
  <IonPage>
    <IonContent className="ion-padding">
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%'
      }}>
        <h2>Updating App</h2>
        <IonSpinner name="circular" />
      </div>
    </IonContent>
  </IonPage>
);

const AppContent: React.FC<{ isNative: boolean }> = ({ isNative }) => {
  const [isUpdating, setIsUpdating] = useState(true);

  return (
    isUpdating ? (
      <SplashScreen onUpdateComplete={() => setIsUpdating(false)} />
    ) : (
    <IonApp>
      <AuthProvider>
        <IonReactRouter>
          <IonRouterOutlet>
            {isNative ? (
              // Routes for iOS and Android
              <>
                <Route path="/auth" component={AuthRoute} exact />
                <ProtectedRoute path="/map" component={Map} exact />
                <Route exact path="/" render={() => <Redirect to="/map" />} />
              </>
            ) : (
              // Routes for web
              <>
                <Route path="/" component={LandingPage} exact />
                <Route path="/privacy" component={PrivacyPolicy} exact />
                <Route path="/terms" component={UsageTerms} exact />
                <Route path="/auth" component={AuthRoute} exact />
                <ProtectedRoute path="/map" component={Map} exact />
              </>
            )}
          </IonRouterOutlet>
        </IonReactRouter>
      </AuthProvider>
    </IonApp>)
  );
};

const App: React.FC = () => {
  const isNative = Capacitor.isNativePlatform();
  const [isUpdating, setIsUpdating] = useState(true);

  useEffect(() => {
    if (isNative) {
      OneSignalInit();
    }
  }, [isNative]);

  async function OneSignalInit(): Promise<void> {
    const appId = process.env.REACT_APP_ONESIGNAL_APP_ID;
    if (!appId) {
      //console.error('OneSignal App ID is not defined');
      return;
    }

    // Initialize OneSignal
    OneSignal.setAppId(appId);

    // Prompt for push notifications
    const accepted = await new Promise<boolean>((resolve) => {
      OneSignal.promptForPushNotificationsWithUserResponse((accepted) => {
        resolve(accepted);
      });
    });

    if (accepted) {
      // Wait for the device state to be available
      const deviceState = await new Promise<any>((resolve) => {
        OneSignal.getDeviceState((state) => resolve(state));
      });

      if (deviceState && deviceState.userId) {
        await sendPushTokenToSupabase(deviceState.userId, Capacitor.getPlatform());
      } else {
        //console.error('Failed to get OneSignal device state or user ID');
      }
    }
  }

  const sendPushTokenToSupabase = async (pushToken: string, deviceType: string) => {
    try {
      const { data: { user }, error } = await supabase.auth.getUser();
      if (user && !error) {
        const { data, error: upsertError } = await supabase.rpc('upsert_push_token', {
          p_player_id: user.id,
          p_push_token: pushToken,
          p_device_type: deviceType
        });

        if (upsertError) {
          //
        } else {
          //
        }
      } else {
        //
      }
    } catch (error) {
      //
    }
  };

  
  return <AppContent isNative={isNative} />;
};

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ component: Component, ...rest }) => {
  const { session, loading } = useAuth();

  if (loading) return <IonSpinner />;

  return (
    <Route
      {...rest}
      render={(props: RouteComponentProps) =>
        session ? <Component {...props} /> : <Redirect to="/auth" />
      }
    />
  );
};

const AuthRoute: React.FC = () => {
  const { session, loading } = useAuth();

  if (loading) return <IonSpinner />;
  return session ? <Redirect to="/map" /> : <Auth />;
};

export default App;

