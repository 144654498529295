import React, { useState, useEffect, useCallback, useRef } from 'react';
import { IonModal, IonContent, IonList, IonItem, IonLabel, IonNote, IonIcon, IonSpinner, IonPopover, IonAvatar, IonFab, IonAlert } from '@ionic/react';
import { cashOutline, timeOutline, walletOutline, trendingUpOutline, chevronDownOutline, checkmarkOutline, businessOutline, cashOutline as cashIcon, settingsOutline, logOutOutline, trashOutline, pauseCircleOutline } from 'ionicons/icons';
import { supabase } from '../../supabaseClient';
import './UserBusinessDrawer.css';
import StatusBarWrapper, { useStatusBarPadding } from '../../utils/useStatusBarPadding';
import RestaurantDetailsModal from '../RestaurantDetailsModal';

interface Business {
  business_id: string;
  business_name: string;
  ownership_percentage: number;
  daily_revenue: number;
}

interface UserFinancials {
  netWorth: number;
  cashAvailable: number;
  assetsValue: number;
}

interface UserBusinessesDrawerProps {
  userId: string | null;
  reloadData: boolean;
  setReloadData: React.Dispatch<React.SetStateAction<boolean>>;
}

const UserBusinessesDrawer: React.FC<UserBusinessesDrawerProps> = ({ userId, reloadData, setReloadData }) => {
  const [businesses, setBusinesses] = useState<Business[]>([]);
  const [totalDailyRevenue, setTotalDailyRevenue] = useState(0);
  const [timeUntilNextPayout, setTimeUntilNextPayout] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [financials, setFinancials] = useState<UserFinancials>({ netWorth: 0, cashAvailable: 0, assetsValue: 0 });
  const [view, setView] = useState<'empire' | 'moneyGenerator' | 'settings'>('moneyGenerator');
  const [moneyGenerated, setMoneyGenerated] = useState(0);
  const [clickPower, setClickPower] = useState(1);
  const [pendingClicks, setPendingClicks] = useState(0);
  const [pendingMoney, setPendingMoney] = useState(0);
  const updateTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const [realTimeNetWorth, setRealTimeNetWorth] = useState(0);
  const [popoverEvent, setPopoverEvent] = useState<Event | null>(null);
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [showFinalDeleteAlert, setShowFinalDeleteAlert] = useState(false);
  const [showDisableAlert, setShowDisableAlert] = useState(false);
  const [selectedBusinessId, setSelectedBusinessId] = useState<string | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const fetchUserStats = async () => {
    if (userId) {
      const { data, error } = await supabase
        .from('user_stats')
        .select('click_power')
        .eq('id', userId)
        .single();
      
      if (error) {
        logError('Error fetching user stats:', error);
      } else if (data) {
        setClickPower(data.click_power);
      }
    }
  };

  useEffect(() => {
    if (userId) {
      fetchUserBusinesses();
      fetchUserFinancials();
      fetchUserStats(); // Fetch click_power here
  
      // Optionally reset reloadData after fetching
      if (reloadData) {
        setReloadData(false); // Reset the state so it can be triggered again in the future
      }
    }
  }, [userId, reloadData]);

  useEffect(() => {
    const interval = setInterval(updateTimeUntilNextPayout, 1000);
    return () => clearInterval(interval);
  }, []);

  const logError = (message: string, error: any) => {
    // Implement your preferred error logging method here
    // For example, you could send errors to a monitoring service
  };

  const fetchUserBusinesses = async () => {
    const { data, error } = await supabase.rpc('get_user_businesses_revenue', { user_id: userId });
    if (error) {
      logError('Error fetching user businesses:', error);
    } else if (data) {
      setBusinesses(data);
      const total = data.reduce((sum: number, business: Business) => sum + business.daily_revenue, 0);
      setTotalDailyRevenue(parseFloat(total.toFixed(2)));
    }
  };

  const fetchUserFinancials = async () => {
    const { data, error } = await supabase.rpc('get_user_financials', { user_id: userId });
    if (error) {
      logError('Error fetching user financials:', error);
    } else if (data && data.length > 0) {
      const financialData = data[0];
      const netWorth = parseFloat(financialData.net_worth?.toFixed(2) || '0');
      setFinancials({
        netWorth: netWorth,
        cashAvailable: parseFloat(financialData.cash_available?.toFixed(2) || '0'),
        assetsValue: parseFloat(financialData.assets_value?.toFixed(2) || '0')
      });
      setRealTimeNetWorth(netWorth);
    } else {
      logError('No financial data returned', null);
    }
    setIsLoading(false);
  };

  const updateTimeUntilNextPayout = () => {
    const now = new Date();
    const nextPayout = new Date(now);
    nextPayout.setHours(6, 0, 0, 0);
    if (now > nextPayout) nextPayout.setDate(nextPayout.getDate() + 1);
    
    const diff = nextPayout.getTime() - now.getTime();
    const hours = Math.floor(diff / (1000 * 60 * 60));
    const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((diff % (1000 * 60)) / 1000);
    
    setTimeUntilNextPayout(`${hours}h ${minutes}m ${seconds}s`);
  };

  const generateMoney = useCallback(() => {
    setPendingClicks(prev => prev + 1);
    const newClickPower = (pendingClicks + 1) % 10 === 0 ? clickPower * 1.1 : clickPower;
    const newMoneyGenerated = newClickPower;

    setPendingMoney(prev => prev + newMoneyGenerated);
    setMoneyGenerated(prev => prev + newMoneyGenerated);
    setRealTimeNetWorth(prev => prev + newMoneyGenerated);

    if ((pendingClicks + 1) % 10 === 0) {
      setClickPower(newClickPower);
    }

    if (updateTimeoutRef.current) {
      clearTimeout(updateTimeoutRef.current);
    }
    updateTimeoutRef.current = setTimeout(updateMoneyInDatabase, 2000);
  }, [pendingClicks, clickPower]);

  const updateMoneyInDatabase = async () => {
    if (pendingMoney > 0 && pendingClicks > 0 && userId) {
      const { error } = await supabase.rpc('update_user_money', {
        amount: pendingMoney,
        clicks: pendingClicks,
        input_user_id: userId
      });

      if (error) {
        logError('Error updating money:', error);
      } else {
        setPendingMoney(0);
        setPendingClicks(0);
        // Update local financial data
        setFinancials(prev => ({
          ...prev,
          netWorth: realTimeNetWorth,
          cashAvailable: prev.cashAvailable + pendingMoney
        }));
      }
    }
  };

  useEffect(() => {
    return () => {
      if (updateTimeoutRef.current) {
        clearTimeout(updateTimeoutRef.current);
      }
      updateMoneyInDatabase();
    };
  }, []);

  const paddingTop = useStatusBarPadding();

  const presentPopover = (e: React.MouseEvent) => {
    setPopoverEvent(e.nativeEvent);
  };

  const handleLogout = async () => {
    await supabase.auth.signOut();
    // Handle post-logout navigation or state reset
  };

  const handleDeleteAccount = async () => {
    setShowDeleteAlert(true);
  };

  const handleDisableAccount = async () => {
    // Implement account disabling logic here
    await supabase.rpc('disable_user_account', { user_id: userId });
    alert('Your account has been disabled. You can reactivate it by logging in again.');
    await handleLogout();
  };

  const confirmDeleteAccount = async () => {
    setShowFinalDeleteAlert(true);
  };

  const finalConfirmDeleteAccount = async () => {
    // Send Discord webhook
    await fetch('https://discord.com/api/webhooks/1288962099397005495/99UrPniMYfAHw0YUXhZTKmejcYqBoVYM5tTQ9muDho2_Jx1TORPHPhljC78qOiSYP-Vf', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ content: `User ${userId} has confirmed account deletion.` }),
    });

    // Log out the user
    await handleLogout();

    // Show a message to the user
    alert('Your account deletion request has been received. Your account will be deleted soon.');
  };

  const handleBusinessClick = (businessId: string) => {
    setSelectedBusinessId(businessId);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedBusinessId(null);
  };

  const renderSettingsView = () => (
    <IonList className="ion-no-padding">
      <IonItem button onClick={handleLogout} lines="full" detail={true}>
        <IonIcon icon={logOutOutline} slot="start" />
        <IonLabel>Log Out</IonLabel>
      </IonItem>
      <IonItem button onClick={() => setShowDisableAlert(true)} lines="full" detail={true}>
        <IonIcon icon={pauseCircleOutline} slot="start" color="warning" />
        <IonLabel color="warning">Disable Account</IonLabel>
      </IonItem>
      <IonItem button onClick={handleDeleteAccount} lines="none" detail={true} className="ion-margin-top">
        <IonIcon icon={trashOutline} slot="start" color="danger" />
        <IonLabel color="danger">Delete Account</IonLabel>
      </IonItem>
    </IonList>
  );

  return (
    <>
      <div> 
        <IonFab vertical="top" horizontal="end" slot="fixed" className=" mr-2" style={{ marginTop: `${paddingTop}px`}}>
          <button
          type="button"
          className="rounded-full bg-black px-2.5 py-1 text-sm font-semibold text-white shadow-sm hover:bg-gray-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          ${realTimeNetWorth.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}
        </button>
        </IonFab>
      </div>

      <IonModal isOpen={true} canDismiss={false} breakpoints={[0.2, 0.5, 0.75, 1]} initialBreakpoint={0.5} backdropBreakpoint={0.75} backdropDismiss={false} className="business-modal">
        <IonContent className="ion-padding business-modal-content ">
          <div className="custom-business-modal rounded-xl relative ">
            <div className="rounded-xl bg-transparent">
              <div className="glassmorphism-content !rounded-3xl">
                <div className="flex items-center justify-between mb-4">
                  <div className="flex items-center cursor-pointer" onClick={presentPopover}>
                    <h1 className="text-2xl font-bold mr-2">
                      {view === 'empire' ? 'Your Empire' : view === 'moneyGenerator' ? 'Money Generator' : 'Settings'}
                    </h1>
                    <IonIcon icon={chevronDownOutline} size="small" />
                  </div>
                  <IonAvatar style={{ width: '40px', height: '40px', backgroundColor: '#4CAF50', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <IonIcon icon={view === 'empire' ? businessOutline : view === 'moneyGenerator' ? cashIcon : settingsOutline} style={{ fontSize: '24px', color: 'white' }} />
                  </IonAvatar>
                </div>

                {isLoading ? (
                <div className="flex justify-center items-center h-full">
                    <IonSpinner />
                </div>
                ) : view === 'empire' ? (
                // Empire View
                <>
                    <div className="mb-6 rounded-lg">
                    <p className="text-lg flex items-center mb-2">
                        <IonIcon icon={walletOutline} className="mr-2" />
                        Net Worth: ${realTimeNetWorth.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}
                    </p>
                    <p className="text-md flex items-center mb-2">
                        <IonIcon icon={cashOutline} className="mr-2" />
                        Cash Available: ${financials.cashAvailable.toLocaleString()}
                    </p>
                    <p className="text-md flex items-center mb-2">
                        <IonIcon icon={trendingUpOutline} className="mr-2" />
                        Assets Value: ${financials.assetsValue.toLocaleString()}
                    </p>
                    </div>
                    
                    <h2 className="text-xl font-semibold mb-2">Your Businesses</h2>
                    <p className="text-lg mb-4">
                    Total Daily Revenue: ${totalDailyRevenue.toFixed(2)}
                    </p>
                    <p className="text-md mb-4 flex items-center">
                    <IonIcon icon={timeOutline} className="mr-2" />
                    Next payout in: {timeUntilNextPayout}
                    </p>
                    <IonList>
                      {businesses.map((business) => (
                        <IonItem 
                          key={business.business_id} 
                          className="my-2 rounded-lg shadow-sm"
                          onClick={() => handleBusinessClick(business.business_id)}
                        >
                          <IonLabel>
                            <h2 className="font-semibold">{business.business_name}</h2>
                            <p className="text-sm text-gray-500">
                              Ownership: {business.ownership_percentage.toFixed(2)}%
                            </p>
                          </IonLabel>
                          <IonNote slot="end" className="text-right">
                            <p className="font-medium">
                              ${business.daily_revenue.toFixed(2)}/day
                            </p>
                          </IonNote>
                        </IonItem>
                      ))}
                    </IonList>
                </>
                ) : view === 'moneyGenerator' ? (
                // Money Generator View
                <div className="flex flex-col items-center justify-center h-full">
                    <p className="text-2xl mb-4">Net Worth: ${realTimeNetWorth.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}</p>
                    <p className="text-xl mb-4">Money Generated: ${moneyGenerated.toFixed(2)}</p>
                    <p className="text-lg mb-2">Click Power: ${clickPower.toFixed(2)}</p>
                    <div
                    onClick={generateMoney}
                    className="flex h-[180px] w-[180px] border border-green-500 rounded-full items-center justify-center text-center text-2xl font-bold cursor-pointer transition-transform active:scale-95"
                    >
                    Tap to Generate Money
                    </div>
                    <p className="text-sm mt-4">Next power increase in {10 - (pendingClicks % 10)} clicks</p>
                    {/* If developement from process.env we display app version 0.0.4*/}
                      <div className="text-xs text-gray-500 mt-4">App Version: 1.0.0</div>
                </div>
                ) : (
                  // Settings View
                  renderSettingsView()
                )}
            </div>
          </div>
        </div>
      </IonContent>
      </IonModal>

      <IonPopover
        event={popoverEvent}
        isOpen={!!popoverEvent}
        onDidDismiss={() => setPopoverEvent(null)}
        style={{ '--background': 'white', '--width': '220px' }}
        mode='ios'
        arrow={false}
      >
        <IonContent>
          <IonList lines="full" className="custom-list">
            <IonItem button detail={false} onClick={() => { setView('empire'); setPopoverEvent(null); }} className="custom-item !rounded-none !pb-0 !mb-0 !border-b-1 !border-gray-300 !pl-1 ">
              <IonIcon icon={view === 'empire' ? checkmarkOutline : undefined} slot="start" color="dark" style={{ marginInlineEnd: '8px', fontSize: '18px' }} />
              <IonLabel className="ion-text-wrap" style={{ fontSize: '14px', fontWeight: '500' }}>Your Empire</IonLabel>
              <IonIcon icon={businessOutline} slot="end" style={{ fontSize: '16px' }} />
            </IonItem>
            <IonItem button detail={false} onClick={() => { setView('moneyGenerator'); setPopoverEvent(null); }} className="custom-item !rounded-none !mb-0 !pl-1">
              <IonIcon icon={view === 'moneyGenerator' ? checkmarkOutline : undefined} slot="start" color="dark" style={{ marginInlineEnd: '8px', fontSize: '18px' }} />
              <IonLabel className="ion-text-wrap" style={{ fontSize: '14px', fontWeight: '500' }}>Money Generator</IonLabel>
              <IonIcon icon={cashIcon} slot="end" style={{ fontSize: '16px' }} />
            </IonItem>
            <IonItem button detail={false} onClick={() => { setView('settings'); setPopoverEvent(null); }} className="custom-item !rounded-none !mb-0 !pl-1">
              <IonIcon icon={view === 'settings' ? checkmarkOutline : undefined} slot="start" color="dark" style={{ marginInlineEnd: '8px', fontSize: '18px' }} />
              <IonLabel className="ion-text-wrap" style={{ fontSize: '14px', fontWeight: '500' }}>Settings</IonLabel>
              <IonIcon icon={settingsOutline} slot="end" style={{ fontSize: '16px' }} />
            </IonItem>
          </IonList>
        </IonContent>
      </IonPopover>

      <IonAlert
        isOpen={showDeleteAlert}
        onDidDismiss={() => setShowDeleteAlert(false)}
        header="Delete Account"
        message="Are you sure you want to delete your account? This action cannot be undone and you will lose all your progress and assets."
        buttons={[
          {
            text: 'Cancel',
            role: 'cancel',
          },
          {
            text: 'Disable Instead',
            handler: () => {
              setShowDeleteAlert(false);
              setShowDisableAlert(true);
            }
          },
          {
            text: 'Delete',
            handler: confirmDeleteAccount,
          },
        ]}
      />

      <IonAlert
        isOpen={showFinalDeleteAlert}
        onDidDismiss={() => setShowFinalDeleteAlert(false)}
        header="Final Confirmation"
        message="This is your last chance to keep your account. Are you absolutely sure you want to delete everything?"
        buttons={[
          {
            text: 'Keep My Account',
            role: 'cancel',
          },
          {
            text: 'Delete Everything',
            handler: finalConfirmDeleteAccount,
          },
        ]}
      />

      <IonAlert
        isOpen={showDisableAlert}
        onDidDismiss={() => setShowDisableAlert(false)}
        header="Disable Account"
        message="Are you sure you want to disable your account? You can reactivate it later by logging in."
        buttons={[
          {
            text: 'Cancel',
            role: 'cancel',
          },
          {
            text: 'Disable',
            handler: handleDisableAccount,
          },
        ]}
      />

      <RestaurantDetailsModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        restaurantId={selectedBusinessId}
        setReloadData={setReloadData}
        reloadData={reloadData}
      />
    </>
  );
};

export default UserBusinessesDrawer;