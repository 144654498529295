import React, { useRef, useState, useEffect } from "react";
import {
  IonContent,
  IonModal,
  IonSearchbar,
  IonList,
  IonItem,
  IonLabel,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
} from "@ionic/react";
import { Geolocation } from '@capacitor/geolocation';
import { supabase } from "../supabaseClient";
import RestaurantDetailsModal from "./RestaurantDetailsModal";
import './SearchBusiness.css';

interface Business {
  id: string;
  name: string;
  category: string;
  business_type: string;
  distance: number;
  address: string;
}

interface SimilarSearch {
  place_name: string;
  center: [number, number];
}

export const SearchBusiness: React.FC<{ 
  setReloadData: React.Dispatch<React.SetStateAction<boolean>>, 
  reloadData: boolean,
  onLocationSelect: (lat: number, lon: number) => void
}> = ({ setReloadData, reloadData, onLocationSelect }) => {
  const modal = useRef<HTMLIonModalElement>(null);
  const contentRef = useRef<HTMLIonContentElement>(null);
  const [businesses, setBusinesses] = useState<Business[]>([]);
  const [page, setPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [isInfiniteDisabled, setInfiniteDisabled] = useState(false);
  const [selectedBusinessId, setSelectedBusinessId] = useState<string | null>(null);
  const [isRestaurantModalOpen, setIsRestaurantModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [userLat, setUserLat] = useState(45.4179255);
  const [userLon, setUserLon] = useState(-75.6959652);
  const [similarSearches, setSimilarSearches] = useState<SimilarSearch[]>([]);
  const businessListRef = useRef<HTMLIonListElement>(null);
  const businessContentRef = useRef<HTMLIonContentElement>(null);

  const loadBusinesses = async (isInitial = false) => {
    if (isInitial) setIsLoading(true);
    try {
      if (searchTerm) {
        const { data, error } = await supabase.rpc('search_businesses_by_name', {
          search_term: searchTerm,
          user_lat: userLat,
          user_lon: userLon,
          business_type_parameter: null,
          page: isInitial ? 1 : page,
          page_size: 25
        });
        if (error) throw error;
        handleBusinessesData(data, isInitial);
      } else {
        const { data, error } = await supabase.rpc('search_nearby_businesses', {
          user_lat: userLat,
          user_lon: userLon,
          search_radius: 250000,
          business_type_parameter: null,
          page: isInitial ? 1 : page,
          page_size: 25
        });
        if (error) throw error;
        handleBusinessesData(data, isInitial);
      }
    } catch (error) {
      logError('Error loading businesses:', error);
    } finally {
      if (isInitial) setIsLoading(false);
    }
  };

  const handleBusinessesData = (data: Business[], isInitial: boolean) => {
    if (isInitial) {
      setBusinesses(data);
      setPage(2);
    } else {
      setBusinesses(prevBusinesses => [...prevBusinesses, ...data]);
      setPage(prevPage => prevPage + 1);
    }
    setInfiniteDisabled(data.length < 25);
  };

  const fetchSimilarSearches = async (query: string) => {
    try {
      const response = await fetch(
        `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(query)}.json?access_token=${process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}&limit=5`
      );
      const data = await response.json();
      setSimilarSearches(data.features.map((feature: any) => ({
        place_name: feature.place_name,
        center: feature.center,
      })));
    } catch (error) {
      //console.error('Error fetching similar searches:', error);
    }
  };

  useEffect(() => {
    const getUserLocation = async () => {
      try {
        const position = await Geolocation.getCurrentPosition();
        setUserLat(position.coords.latitude);
        setUserLon(position.coords.longitude);
      } catch (geoError) {
        //console.warn('Geolocation not available, using default values:', geoError);
      }
    };

    getUserLocation().then(() => loadBusinesses(true));
  }, []);

  useEffect(() => {
    loadBusinesses(true);
  }, [searchTerm]);

  const handleSearch = (e: CustomEvent) => {
    setSearchTerm(e.detail.value ?? '');
    setPage(1);
    setBusinesses([]);
    if (e.detail.value === '') {
      setSimilarSearches([]);
    } else {
      fetchSimilarSearches(e.detail.value);
    }
  };

  const loadMore = async (ev: CustomEvent<void>) => {
    await loadBusinesses(false);
    (ev.target as HTMLIonInfiniteScrollElement).complete();
  };

  const openRestaurantDetails = (businessId: string) => {
    setSelectedBusinessId(businessId);
    setIsRestaurantModalOpen(true);
    modal.current?.dismiss();
  };

  const closeRestaurantDetails = () => {
    setIsRestaurantModalOpen(false);
    setSelectedBusinessId(null);
  };

  const handleSimilarSearchSelect = (lat: number, lon: number) => {
    onLocationSelect(lat, lon);
    modal.current?.dismiss();
  };

  return (
    <>
      <IonModal
        ref={modal}
        trigger="open-modal-search-business"
        initialBreakpoint={0.75}
        breakpoints={[0.25, 0.5, 0.75, 1]}
        backdropDismiss={true}
        className="search-business-modal"
      >
        <IonContent className="ion-padding !bg-transparent">
          <div className="rounded-full p-2 mb-0 flex items-center">
            <IonSearchbar
              onIonFocus={() => {
                modal.current?.setCurrentBreakpoint(1);
              }}
              placeholder="Search"
              mode="ios"
              value={searchTerm}
              onIonChange={handleSearch}
              className="custom-searchbar"
            />
          </div>
          {isLoading && <div className="text-center">Loading...</div>}
          {!isLoading && (
            <div className="flex flex-col h-full">
              {businesses.length > 0 && (
                <div className={`flex-grow ${searchTerm ? 'h-[45vh]' : 'h-[85vh]'}`}>
                  <IonContent ref={businessContentRef} className="h-full">
                    <IonList className="!bg-white !rounded-lg" inset={true} lines="inset">
                      {businesses.map((business) => (
                        <IonItem 
                          key={business.id} 
                          className="!border-b !border-gray-200 rounded-none"
                          onClick={() => openRestaurantDetails(business.id)}
                        >
                          <IonLabel>{business.name}</IonLabel>
                        </IonItem>
                      ))}
                    </IonList>
                    <IonInfiniteScroll
                      onIonInfinite={loadMore}
                      threshold="100px"
                      disabled={isInfiniteDisabled}
                    >
                      <IonInfiniteScrollContent />
                    </IonInfiniteScroll>
                  </IonContent>
                </div>
              )}
              {searchTerm && similarSearches.length > 0 && (
                <div className={`${businesses.length > 0 ? 'h-[40vh] mt-4' : 'h-full'}`}>
                  <h3 className="text-lg font-semibold mb-2 ml-4">Similar Searches</h3>
                  <IonList className="!bg-white !rounded-lg" inset={true} lines="inset">
                    {similarSearches.map((search, index) => (
                      <IonItem 
                        key={index} 
                        className="!border-b !border-gray-200 rounded-none"
                        onClick={() => handleSimilarSearchSelect(search.center[1], search.center[0])}
                      >
                        <IonLabel>{search.place_name}</IonLabel>
                      </IonItem>
                    ))}
                  </IonList>
                </div>
              )}
              {!isLoading && searchTerm && businesses.length === 0 && similarSearches.length === 0 && (
                <div className="text-center mt-4">No results found</div>
              )}
            </div>
          )}
        </IonContent>
      </IonModal>
      <RestaurantDetailsModal
        isOpen={isRestaurantModalOpen}
        onClose={closeRestaurantDetails}
        restaurantId={selectedBusinessId}
        setReloadData={setReloadData}
        reloadData={reloadData}
      />
    </>
  );
};

const logError = (message: string, error: unknown) => {
  //console.error(message, error);
};