import React, { useState, useRef, useEffect } from 'react';
import {
  IonButtons,
  IonButton,
  IonModal,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonSearchbar,
  IonBadge,
  IonActionSheet,
  IonChip,
  IonIcon,
} from '@ionic/react';
import { closeCircle } from 'ionicons/icons';
import AuctionList from './AuctionList';
import './BiddingModal.css'

interface AuctionProps {
  isOpen: boolean;
  onClose: () => void;
  page: any;
}

const categories = [
  { label: 'Rarity', color: 'primary' },
  { label: 'Valuation', color: 'success' },
  { label: 'Restaurant Type', color: 'danger' },
];

const Auction: React.FC<AuctionProps> = ({ isOpen, onClose, page }) => {
  const modal = useRef<HTMLIonModalElement>(null);
  const [presentingElement, setPresentingElement] = useState<HTMLElement | null>(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedRarity, setSelectedRarity] = useState<string | null>(null);
  const [selectedValuation, setSelectedValuation] = useState<string | null>(null);
  const [selectedBusinessType, setSelectedBusinessType] = useState<string | null>(null);
  const [actionSheetState, setActionSheetState] = useState<{ isOpen: boolean; category: string }>({ isOpen: false, category: '' });
  const [activeFilters, setActiveFilters] = useState<{[key: string]: string | null}>({
    Rarity: null,
    Valuation: null,
    'Restaurant Type': null,
  });

  useEffect(() => {
    setPresentingElement(page.current);
  }, [page]);

  useEffect(() => {
    if (isOpen) {
      modal.current?.present();
    }
  }, [isOpen]);

  const handleSearch = (event: CustomEvent) => {
    setSearchTerm(event.detail.value);
  };

  const openActionSheet = (category: string) => {
    setActionSheetState({ isOpen: true, category });
  };

  const getActionSheetButtons = (category: string) => {
    const buttons = {
      'Rarity': [
        { text: 'Common', handler: () => updateFilter('Rarity', 'common') },
        { text: 'Rare', handler: () => updateFilter('Rarity', 'rare') },
        { text: 'Epic', handler: () => updateFilter('Rarity', 'epic') },
        { text: 'Legendary', handler: () => updateFilter('Rarity', 'legendary') },
      ],
      'Valuation': [
        { text: '$0 - $1,000,000', handler: () => updateFilter('Valuation', '0-1000000') },
        { text: '$1,000,000 - $5,000,000', handler: () => updateFilter('Valuation', '1000000-5000000') },
        { text: '$5,000,000 - $10,000,000', handler: () => updateFilter('Valuation', '5000000-10000000') },
        { text: '$10,000,000+', handler: () => updateFilter('Valuation', '10000000+') },
      ],
      'Restaurant Type': [
        { text: 'Restaurant', handler: () => updateFilter('Restaurant Type', 'Restaurant') },
        { text: 'Cafe', handler: () => updateFilter('Restaurant Type', 'Cafe') },
        { text: 'Bar', handler: () => updateFilter('Restaurant Type', 'Bar') },
      ],
    };
    return buttons[category as keyof typeof buttons] || [];
  };

  const updateFilter = (category: string, value: string) => {
    setActiveFilters(prev => ({ ...prev, [category]: value }));
    if (category === 'Rarity') setSelectedRarity(value);
    else if (category === 'Valuation') setSelectedValuation(value);
    else if (category === 'Restaurant Type') setSelectedBusinessType(value);
  };

  const resetFilter = (category: string) => {
    setActiveFilters(prev => ({ ...prev, [category]: null }));
    if (category === 'Rarity') setSelectedRarity(null);
    else if (category === 'Valuation') setSelectedValuation(null);
    else if (category === 'Restaurant Type') setSelectedBusinessType(null);
  };

  const resetAllFilters = () => {
    setActiveFilters({
      Rarity: null,
      Valuation: null,
      'Restaurant Type': null,
    });
    setSelectedRarity(null);
    setSelectedValuation(null);
    setSelectedBusinessType(null);
  };

  return (
    <IonModal
      ref={modal}
      isOpen={isOpen}
      mode='ios'
      onDidDismiss={onClose}
      canDismiss={true}
      presentingElement={presentingElement as any}
      className='ion-modal-auction'
      backdropDismiss={true}
    >
      <IonHeader className="bg-white/80 backdrop-blur-xl">
        <IonToolbar>
          <IonTitle>Auction</IonTitle>
          <IonButtons slot="end">
            <IonButton color='dark' className="!mr-2" onClick={onClose}>Close</IonButton>
          </IonButtons>
        </IonToolbar>
        <IonToolbar>
          <IonSearchbar showCancelButton='focus' mode='ios' placeholder="Restaurants, Addresses, Locations and More" className="ion-padding searchbar-input" onIonChange={handleSearch} />
        </IonToolbar>
        <IonToolbar className="px-4 ml-4 sm:ml-0 -mt-3 overflow-x-auto whitespace-nowrap">
          {categories.map((category, index) => (
            <IonBadge 
              key={index} 
              color={activeFilters[category.label] ? 'medium' : category.color}
              className={`text-[#007bff] border border-gray-300 bg-white mr-2 cursor-pointer ${activeFilters[category.label] ? 'p-0' : ''}`}
              onClick={() => openActionSheet(category.label)}
            >
              {activeFilters[category.label] ? (
                <IonChip 
                  color="light" 
                  className="m-0 h-full"
                  onClick={(e) => { e.stopPropagation(); resetFilter(category.label); }}
                >
                  {activeFilters[category.label]} <IonIcon icon={closeCircle} />
                </IonChip>
              ) : (
                category.label
              )}
            </IonBadge>
          ))}
          {Object.values(activeFilters).some(filter => filter !== null) && (
            <IonButton fill="clear" size="small" onClick={resetAllFilters}>
              Reset All
            </IonButton>
          )}
        </IonToolbar>
      </IonHeader>
      
      <AuctionList 
        searchTerm={searchTerm}
        selectedRarity={selectedRarity}
        selectedPriceRange={selectedValuation}
        selectedBusinessType={selectedBusinessType}
      />

      <IonActionSheet
        isOpen={actionSheetState.isOpen}
        onDidDismiss={() => setActionSheetState({ isOpen: false, category: '' })}
        header={actionSheetState.category}
        buttons={[
          ...getActionSheetButtons(actionSheetState.category),
          { text: 'Cancel', role: 'cancel' }
        ]}
        cssClass="action-sheet-custom"
        className="!z-[9999002]"
      />
    </IonModal>
  );
};

export default Auction;
